<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Setup</h1>
                </v-col>
            </v-row>
            <!-- TODO: loginfront setup ONLY; after that admin can login and do rest of setup -->
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Registration mode</p>
                    <p class="mb-0 pb-0">
                        <span>{{ registrationModeText }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditRegistrationMode = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditRegistrationMode" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Edit registration mode</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>There are 4 modes available:</p>
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Open registration</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up to create new accounts and manage realms within their accounts. This is a good setting for a production SaaS deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Approval required</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up, but all requests are placed in a queue to be approved by an administrator. The accounts are not created without approval. This is a good setting for a "public beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>By invitation only</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed and people can only create accounts with an invitation. Only service administrators can send invitations. This is a good setting for a "private alpha" or "private beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Closed</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed. Service administrators can still create new accounts and realms directly, but new administrator accounts cannot be created. This is the default setting.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-form @submit="editRegistrationMode" onSubmit="return false;" @keyup.enter.native="editRegistrationMode" class="mt-5">
                                    <v-select
                                        v-model="editableRegistrationMode"
                                        :items="registrationModeChoices"
                                        label="Registration mode"
                                        outlined>
                                    </v-select>
                                    <v-card-actions>
                                        <v-row justify="center">
                                        </v-row>
                                    </v-card-actions>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editRegistrationMode" :disabled="!isEditRegistrationModeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditRegistrationMode = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <p class="text-overline mb-0 mt-8">Registration</p>
                    <p class="mb-0 pb-0">
                        <router-link>Allow open registration</router-link>
                    </p> -->
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableRegistrationMode
                                color="teal"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editRegistrationMode" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="teal"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
// import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

/**
 * This is a special page for administrators to grant themselves the
 * service admin role. First, the administrator must create for themselves
 * a user in LoginFront and login. Second, the administrator must
 * create the service setup interaction using the `brandprofile admin link`
 * command. The administrator will be redirected to this page with the
 * `i` query parameter value being the interaction id of the `service_setup`
 * interaction, for which the admin's session is authorized when the
 * admin follows that link.
 */

export default {
    data: () => ({
        isViewReady: false,
        registrationMode: 'closed',
        dialogEditRegistrationMode: false,
        editableRegistrationMode: null,
        registrationModeChoices: [
            { text: 'Open registration', value: 'open' },
            { text: 'Approval required', value: 'open_moderated' },
            { text: 'By invitation only', value: 'invitation' },
            { text: 'Closed', value: 'closed' },
        ],
    }),
    computed: {
        registrationModeText() {
            return this.registrationModeChoices.find((item) => item.value === this.registrationMode)?.text ?? `Unknown (${this.registrationMode})`;
        },
        isEditRegistrationModeFormComplete() {
            return typeof this.editableRegistrationMode === 'string' && this.editableRegistrationMode !== this.registrationMode;
        },
    },
    watch: {
        dialogEditRegistrationMode(value) {
            if (value) {
                this.editableRegistrationMode = this.registrationMode;
            }
        },
    },
    methods: {
        async editServiceConfig(name, content) {
            try {
                this.$store.commit('loading', { editServiceConfig: true });
                const response = await this.$client.service.editSetting({ name, content });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Saved changes' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                }
                return response?.isEdited ?? false;
            } catch (err) {
                console.error('failed to edit setting', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                return false;
            } finally {
                this.$store.commit('loading', { editServiceConfig: false });
                this.isViewReady = true;
            }
        },
        async editRegistrationMode() {
            const isEdited = await this.editServiceConfig('registration_mode', this.editableRegistrationMode);
            if (isEdited) {
                this.registrationMode = this.editableRegistrationMode;
            }
            this.dialogEditRegistrationMode = false;
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.service.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    this.registrationMode = settingsMap.registration_mode ?? 'closed';
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.loadServiceSettingsList();
    },
};
</script>
